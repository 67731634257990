<template>
  <div class="text-gray-700">
    <p>
      hMail provides email services for handshake names. Set up and get an email
      address anything@handshake name.
    </p>
    <p class="mt-2">
      <span class="font-medium">Note:</span> This only sets up the dns end to
      use hMail, you still need to register and pay for hMail after this.<br />
      Visit
      <a
        href="https://hmail.app/register.html"
        target="_blank"
        rel="noopener noreferrer"
        class="underline"
        >https://hmail.app/register.html</a
      >
      to complete registration (after the zone is hosted).
    </p>

    <!-- Empty domain error -->
    <div v-if="!domain.length" class="mt-4">
      <p class="text-red-600 text-sm">
        Make sure the domain field is filled at the top of the page!
      </p>
    </div>

    <!-- Section 1 -->
    <section>
      <div class="mt-8 flex items-baseline font-medium">
        <div class="h-5 w-5 bg-gray-200 text-sm text-center rounded-full">
          1
        </div>
        <p class="ml-4">Email address</p>
      </div>
      <div class="ml-9 mt-2 flex items-center">
        <input type="checkbox" v-model="wantSubdomain" />
        <p class="ml-2">
          I want the address at a subdomain (<span class="italic"
            >bob@abc.{{ domain || 'domain' }}</span
          >) instead of root level (<span class="italic"
            >bob@{{ domain || 'domain' }}</span
          >)
        </p>
      </div>
      <div class="ml-9 mt-2">
        <div class="mb-3 flex flex-wrap">
          <input
            type="text"
            v-model="user"
            placeholder="user"
            class="px-3 py-2 w-40 text-sm text-right text-gray-700 placeholder-gray-400 bg-white rounded rounded-r-none border border-r-none"
          />
          <span
            v-if="wantSubdomain"
            class="right-0 px-2 py-2 h-full leading-snug font-normal text-center text-gray-600 bg-transparent bg-gray-50 border text-base"
          >
            @
          </span>
          <input
            v-if="wantSubdomain"
            type="text"
            v-model="subdomain"
            placeholder="subdomain"
            class="px-3 py-2 w-40 text-sm text-center text-gray-700 placeholder-gray-400 bg-white border border-r-none"
          />
          <span
            class="right-0 px-2 py-2 h-full leading-snug font-normal text-center text-gray-600 bg-transparent bg-gray-50 rounded-md rounded-l-none border text-base"
          >
            <span v-if="!wantSubdomain">@</span>
            <span v-else>.</span>
            {{ domain || 'domain' }}
          </span>
        </div>
      </div>
    </section>

    <!-- Section 2 -->
    <section>
      <div class="mt-8 flex items-baseline font-medium">
        <div class="h-5 w-5 bg-gray-200 text-sm text-center rounded-full">
          2
        </div>
        <p class="ml-4">Confirm</p>
      </div>
      <div class="ml-9 mt-2">
        <p>
          These 3 records will be added to the zone:
        </p>
        <EditableZoneTable
          class="mt-4 overflow-hidden"
          :editable="false"
          :readOnlyRecords="records"
        />
      </div>
      <div class="ml-9 mt-2"></div>
    </section>

    <!-- Add button -->
    <div class="flex items-center justify-center">
      <button
        v-if="domain.length"
        @click="$emit('add-records', [])"
        class="mt-2 px-4 py-1 text-gray-800 rounded"
      >
        cancel
      </button>
      <button
        v-if="domain.length"
        @click="addRecords"
        class="mt-2 px-6 py-1 text-gray-100 bg-gray-700 rounded"
        :class="{
          'opacity-50 cursor-default': !canAdd,
        }"
        :disabled="!canAdd"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script>
import EditableZoneTable from '@/components/EditableZoneTable.vue'

export default {
  name: 'MailModal',
  props: {
    domain: {
      type: String,
      default: 'domain',
    },
  },
  components: {
    EditableZoneTable,
  },
  data() {
    return {
      wantSubdomain: false,
      user: '',
      subdomain: '',
    }
  },
  methods: {
    addRecords() {
      this.$emit('add-records', this.records)
    },
  },
  computed: {
    records() {
      const subdomainPrefix = this.wantSubdomain ? `.${this.subdomain}` : ''
      return [
        {
          type: 'MX',
          name: this.wantSubdomain ? this.subdomain : '@',
          value: '10 hmail.app.',
          ttl: 3600,
        },
        {
          type: 'TXT',
          name: '_hmail._auth' + subdomainPrefix,
          value: this.user || 'user',
          ttl: 3600,
        },
        {
          type: 'TXT',
          name: this.wantSubdomain ? this.subdomain : '@',
          value: '"v=spf1 include:hmail.app"',
          ttl: 3600,
        },
      ]
    },
    canAdd() {
      return (
        this.domain.length &&
        this.user.length &&
        /^[\w.]*?\w+$/gi.test(this.user) &&
        (this.wantSubdomain
          ? this.subdomain.length && /^[\w.]*?\w+$/gi.test(this.subdomain)
          : true)
      )
    },
  },
}
</script>

<style scoped lang="scss"></style>
