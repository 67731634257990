const x509 = window.x509

// https://www.npmjs.com/package/@peculiar/x509#create-a-self-signed-certificate
async function generateCertificate(hostname) {
  console.log('Running.')
  const alg = {
    name: 'RSASSA-PKCS1-v1_5',
    hash: 'SHA-256',
    publicExponent: new Uint8Array([1, 0, 1]),
    modulusLength: 2048,
  }
  const keys = await crypto.subtle.generateKey(alg, true, ['sign', 'verify'])
  console.log('Generated keys:', keys)
  let cert
  try {
    const now = new Date()
    const expiry = new Date()
    expiry.setFullYear(expiry.getFullYear() + 1)
    cert = await x509.X509CertificateGenerator.createSelfSigned({
      serialNumber: '01',
      name: `CN=${hostname}`,
      notBefore: now,
      notAfter: expiry,
      signingAlgorithm: alg,
      keys,
      extensions: [
        new x509.BasicConstraintsExtension(false, undefined, true),
        new x509.ExtendedKeyUsageExtension(
          ['1.3.6.1.5.5.7.3.1', '1.3.6.1.5.5.7.3.2'],
          true
        ),
        new x509.KeyUsagesExtension(
          x509.KeyUsageFlags.digitalSignature |
            x509.KeyUsageFlags.keyEncipherment,
          true
        ),
        new x509.SubjectAlternativeNameExtension({
          dns: [hostname, `*.${hostname}`],
        }),
        await x509.SubjectKeyIdentifierExtension.create(keys.publicKey),
      ],
    })
  } catch (err) {
    console.error(err)
  }
  console.log('Generated cert:', cert)
  console.log(cert.toString('pem')) // Certificate in PEM format

  return {
    certificate: cert.toString('pem'),
    publicKey: spkiToPEM(
      'public',
      await crypto.subtle.exportKey('spki', keys.publicKey)
    ),
    privateKey: spkiToPEM(
      'private',
      await crypto.subtle.exportKey('pkcs8', keys.privateKey)
    ),
  }
}

// https://stackoverflow.com/questions/40314257/export-webcrypto-key-to-pem-format/40327542#40327542
function spkiToPEM(type, keydata) {
  var keydataS = arrayBufferToString(keydata)
  var keydataB64 = window.btoa(keydataS)
  var keydataB64Pem = formatAsPem(type, keydataB64)
  return keydataB64Pem
}

function arrayBufferToString(buffer) {
  var binary = ''
  var bytes = new Uint8Array(buffer)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return binary
}

function formatAsPem(type, str) {
  var finalString
  if (type == 'public') {
    finalString = '-----BEGIN PUBLIC KEY-----\n'
  } else {
    finalString = '-----BEGIN PRIVATE KEY-----\n'
  }

  while (str.length > 0) {
    finalString += str.substring(0, 64) + '\n'
    str = str.substring(64)
  }

  if (type == 'public') {
    finalString = finalString + '-----END PUBLIC KEY-----'
  } else {
    finalString = finalString + '-----END PRIVATE KEY-----'
  }
  return finalString
}

async function generateTLSAHash(data) {
  const arrbuff = base64ToArrayBuffer(
    data
      .replace(/\n/g, '')
      .replace(/\r/g, '')
      .replace('-----BEGIN CERTIFICATE-----', '')
      .replace('-----END CERTIFICATE-----', '')
      .replace('-----BEGIN PUBLIC KEY-----', '')
      .replace('-----END PUBLIC KEY-----', '')
      .replace(/ /g, '')
  )
  const hexHash = Array.from(
    new Uint8Array(await crypto.subtle.digest('SHA-256', arrbuff))
  )
    .map(b => b.toString(16).padStart(2, '0'))
    .join('')
  return hexHash
}

function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64)
  var len = binary_string.length
  var bytes = new Uint8Array(len)
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

module.exports = { generateCertificate, generateTLSAHash }
