<template>
  <transition name="fade" mode="out-in">
    <div
      v-show="show"
      @click="close"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30"
    >
      <div
        @click.stop=""
        class="w-11/12 md:w-8/12 flex flex-col bg-white rounded border shadow-2xl max-h-screen overflow-auto"
        role="dialog"
      >
        <header
          class="px-6 py-4 flex justify-between text-gray-700 font-medium border-b border-gray-200"
        >
          <slot name="header"></slot>
        </header>
        <section class="relative px-4 py-4">
          <slot name="body"></slot>
        </section>
        <!-- <footer class="p-4 flex justify-end border-t border-gray-200">
          <slot name="footer"></slot>
        </footer> -->
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    show: Boolean,
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>
