<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name (may be hashed)
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Types
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Signed
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="name in recordsList" :key="name.name">
                <td class="px-6 py-2 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="">
                      <div class="text-sm font-medium text-gray-900">
                        {{ name.name }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-2 whitespace-nowrap">
                  <div class="text-sm text-gray-900">
                    <span class="font-medium">
                      {{
                        Array.from(name.rrs.keys())
                          .filter(x => x !== 46)
                          .map(x => typesByVal[x])
                          .join(', ')
                      }}
                    </span>
                  </div>
                  <div class="text-sm text-gray-500">
                    <span class="font-medium">and {{ name.sigs.size }}</span>
                    {{ name.sigs.size == 1 ? 'signature' : 'signatures' }}
                  </div>
                </td>
                <td class="px-6 py-2 whitespace-nowrap">
                  <span
                    class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                  >
                    Yes
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZoneTable',
  props: {
    zone: Object,
  },
  data() {
    return {
      typesByVal: typesByVal,
    }
  },
  methods: {},
  computed: {
    records() {
      return JSON.parse(JSON.stringify(this.zone), reviver).names
      // console.log(this.records)
    },
    recordsList() {
      const recordsList = []
      const a = Object.fromEntries(this.records)
      for (let x in a) {
        recordsList.push({ name: x, ...a[x] })
      }
      // console.log(recordsList)
      return recordsList
    },
  },
}

function reviver(key, value) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value)
    }
  }
  return value
}

const types = {
  UNKNOWN: 0,
  A: 1,
  NS: 2,
  MD: 3, // obsolete
  MF: 4, // obsolete
  CNAME: 5,
  SOA: 6,
  MB: 7, // experimental
  MG: 8, // experimental
  MR: 9, // experimental
  NULL: 10, // obsolete
  WKS: 11, // deprecated
  PTR: 12,
  HINFO: 13, // not-in-use
  MINFO: 14, // experimental
  MX: 15,
  TXT: 16,
  RP: 17,
  AFSDB: 18,
  X25: 19, // not-in-use
  ISDN: 20, // not-in-use
  RT: 21, // not-in-use
  NSAP: 22, // not-in-use
  NSAPPTR: 23, // not-in-use
  SIG: 24, // obsolete
  KEY: 25, // obsolete
  PX: 26, // not-in-use
  GPOS: 27, // deprecated
  AAAA: 28,
  LOC: 29,
  NXT: 30, // obsolete
  EID: 31, // not-in-use
  NIMLOC: 32, // not-in-use (used to be NB)
  SRV: 33, // used to be NBSTAT
  ATMA: 34, // not-in-use
  NAPTR: 35,
  KX: 36,
  CERT: 37,
  A6: 38, // historic
  DNAME: 39,
  SINK: 40, // unimpl (joke?)
  OPT: 41, // impl (pseudo-record, edns)
  APL: 42, // not-in-use
  DS: 43,
  SSHFP: 44,
  IPSECKEY: 45,
  RRSIG: 46,
  NSEC: 47,
  DNSKEY: 48,
  DHCID: 49,
  NSEC3: 50,
  NSEC3PARAM: 51,
  TLSA: 52,
  SMIMEA: 53,

  // 54 is unassigned

  HIP: 55,
  NINFO: 56, // proposed
  RKEY: 57, // proposed
  TALINK: 58, // proposed
  CDS: 59,
  CDNSKEY: 60,
  OPENPGPKEY: 61,
  CSYNC: 62,

  // 63-98 are unassigned

  SPF: 99, // obsolete
  UINFO: 100, // obsolete
  UID: 101, // obsolete
  GID: 102, // obsolete
  UNSPEC: 103, // obsolete
  NID: 104,
  L32: 105,
  L64: 106,
  LP: 107,
  EUI48: 108,
  EUI64: 109,

  // 110-248 are unassigned

  TKEY: 249,
  TSIG: 250,
  IXFR: 251, // unimpl (pseudo-record)
  AXFR: 252, // unimpl (pseudo-record)
  MAILB: 253, // experimental, unimpl (qtype)
  MAILA: 254, // obsolete, unimpl (qtype)

  ANY: 255, // impl (qtype)
  URI: 256,
  CAA: 257,
  AVC: 258, // proposed
  DOA: 259, // proposed
  // OX: 260, // proposed successor to DOA?

  // 260-32767 are unassigned

  TA: 32768,
  DLV: 32769,

  // 32770-65279 are unassigned
  // 65280-65534 reserved for private use

  RESERVED: 65535, // unimpl
}
const typesByVal = {
  [types.UNKNOWN]: 'UNKNOWN',
  [types.A]: 'A',
  [types.NS]: 'NS',
  [types.MD]: 'MD',
  [types.MF]: 'MF',
  [types.CNAME]: 'CNAME',
  [types.SOA]: 'SOA',
  [types.MB]: 'MB',
  [types.MG]: 'MG',
  [types.MR]: 'MR',
  [types.NULL]: 'NULL',
  [types.WKS]: 'WKS',
  [types.PTR]: 'PTR',
  [types.HINFO]: 'HINFO',
  [types.MINFO]: 'MINFO',
  [types.MX]: 'MX',
  [types.TXT]: 'TXT',
  [types.RP]: 'RP',
  [types.AFSDB]: 'AFSDB',
  [types.X25]: 'X25',
  [types.ISDN]: 'ISDN',
  [types.RT]: 'RT',
  [types.NSAP]: 'NSAP',
  [types.NSAPPTR]: 'NSAPPTR',
  [types.SIG]: 'SIG',
  [types.KEY]: 'KEY',
  [types.PX]: 'PX',
  [types.GPOS]: 'GPOS',
  [types.AAAA]: 'AAAA',
  [types.LOC]: 'LOC',
  [types.NXT]: 'NXT',
  [types.EID]: 'EID',
  [types.NIMLOC]: 'NIMLOC',
  [types.SRV]: 'SRV',
  [types.ATMA]: 'ATMA',
  [types.NAPTR]: 'NAPTR',
  [types.KX]: 'KX',
  [types.CERT]: 'CERT',
  [types.A6]: 'A6',
  [types.DNAME]: 'DNAME',
  [types.SINK]: 'SINK',
  [types.OPT]: 'OPT',
  [types.APL]: 'APL',
  [types.DS]: 'DS',
  [types.SSHFP]: 'SSHFP',
  [types.IPSECKEY]: 'IPSECKEY',
  [types.RRSIG]: 'RRSIG',
  [types.NSEC]: 'NSEC',
  [types.DNSKEY]: 'DNSKEY',
  [types.DHCID]: 'DHCID',
  [types.NSEC3]: 'NSEC3',
  [types.NSEC3PARAM]: 'NSEC3PARAM',
  [types.TLSA]: 'TLSA',
  [types.SMIMEA]: 'SMIMEA',
  [types.HIP]: 'HIP',
  [types.NINFO]: 'NINFO',
  [types.RKEY]: 'RKEY',
  [types.TALINK]: 'TALINK',
  [types.CDS]: 'CDS',
  [types.CDNSKEY]: 'CDNSKEY',
  [types.OPENPGPKEY]: 'OPENPGPKEY',
  [types.CSYNC]: 'CSYNC',
  [types.SPF]: 'SPF',
  [types.UINFO]: 'UINFO',
  [types.UID]: 'UID',
  [types.GID]: 'GID',
  [types.UNSPEC]: 'UNSPEC',
  [types.NID]: 'NID',
  [types.L32]: 'L32',
  [types.L64]: 'L64',
  [types.LP]: 'LP',
  [types.EUI48]: 'EUI48',
  [types.EUI64]: 'EUI64',
  [types.TKEY]: 'TKEY',
  [types.TSIG]: 'TSIG',
  [types.IXFR]: 'IXFR',
  [types.AXFR]: 'AXFR',
  [types.MAILB]: 'MAILB',
  [types.MAILA]: 'MAILA',
  [types.URI]: 'URI',
  [types.CAA]: 'CAA',
  [types.AVC]: 'AVC',
  [types.DOA]: 'DOA',
  // [types.OX]: 'OX',
  [types.ANY]: 'ANY',
  [types.TA]: 'TA',
  [types.DLV]: 'DLV',
  [types.RESERVED]: 'RESERVED',
}
</script>

<style scoped lang="scss"></style>
