<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Type
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Value
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  TTL
                </th>
                <th v-if="editable" scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <!-- Records List -->
              <tr v-for="(record, idx) in records" :key="idx">
                <td class="px-6 py-2 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="">
                      <div class="text-sm font-medium text-gray-600">
                        {{ record.type }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-2 whitespace-nowrap">
                  <div class="text-sm text-gray-600">
                    <span class="font-medium">
                      {{ record.name }}
                    </span>
                  </div>
                </td>
                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-600">
                  {{ record.value }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  {{ record.ttl }}
                </td>
                <td
                  v-if="editable"
                  class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                >
                  <button
                    @click="records.pop(idx)"
                    class="text-indigo-600 hover:text-indigo-900"
                  >
                    Remove
                  </button>
                </td>
              </tr>

              <!-- Add New Record -->
              <tr v-if="editable" class="text-sm">
                <!-- Type -->
                <td class="px-6 py-2 whitespace-nowrap">
                  <div class="relative inline-flex">
                    <svg
                      class="w-2 h-2 absolute top-0 right-0 m-3 pointer-events-none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 412 232"
                    >
                      <path
                        d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                        fill="#648299"
                        fill-rule="nonzero"
                      />
                    </svg>
                    <select
                      v-model="newType"
                      class="border border-gray-300 rounded text-gray-600 h-8 pl-3 pr-6 bg-white hover:border-gray-400 appearance-none"
                    >
                      <option value="" selected disabled>Type</option>
                      <option value="A">A</option>
                      <option value="CNAME">CNAME</option>
                      <option value="TXT">TXT</option>
                      <option value="MX">MX</option>
                      <option value="TLSA">TLSA</option>
                    </select>
                  </div>
                </td>

                <!-- Name -->
                <td class="px-6 py-2 whitespace-nowrap">
                  <input
                    v-model="newName"
                    type="text"
                    class="w-32 px-2 py-1 rounded border"
                    placeholder="Name (@=root)"
                  />
                </td>

                <!-- Value -->
                <td class="px-6 py-2 whitespace-nowrap">
                  <input
                    v-model="newValue"
                    type="text"
                    class="w-full px-2 py-1 rounded border"
                    :placeholder="valuePlaceholder"
                  />
                </td>

                <!-- TTL -->
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <input
                    v-model.number="newTTL"
                    type="number"
                    class="w-16 px-2 py-1 rounded border appearance-none"
                    placeholder="3600"
                  />
                </td>

                <!-- Action -->
                <td
                  class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                >
                  <button
                    @click="addNewRecord"
                    class="px-4 py-1 rounded text-gray-800 bg-gray-200"
                    :class="{
                      'opacity-50 cursor-default': !canAddRecord,
                    }"
                    :disabled="!canAddRecord"
                  >
                    Add
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditableZoneTable',
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    readOnlyRecords: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update'],
  data() {
    return {
      newType: '',
      newName: '',
      newValue: '',
      newTTL: 3600,
      records: [
        // {
        //   type: 'A',
        //   name: '@',
        //   value: '1.1.1.1',
        //   ttl: 1800,
        // },
        // {
        //   type: 'CNAME',
        //   name: 'abc',
        //   value: 'example.com',
        //   ttl: 3600,
        // },
        // {
        //   type: 'TXT',
        //   name: 'def',
        //   value: 'sometextcontent',
        //   ttl: 3600,
        // },
        // {
        //   type: 'MX',
        //   name: '@',
        //   value: 'hmail.app',
        //   ttl: 3600,
        // },
      ],
    }
  },
  mounted() {
    if (this.readOnlyRecords.length) {
      this.records = this.readOnlyRecords
    }
    this.$emit('update', this.records)
  },
  methods: {
    addNewRecord() {
      this.records.push({
        type: this.newType,
        name: this.newName || '@',
        value: this.newValue,
        ttl: this.newTTL,
      })
    },
    addRecords(records) {
      this.records = [...this.records, ...records]
    },
  },
  computed: {
    valuePlaceholder() {
      switch (this.newType) {
        case 'A':
          return 'IP Address'
        case 'CNAME':
          return 'Hostname'
        default:
          return 'Value'
      }
    },
    isValidType() {
      return !!this.newType
    },
    isValidName() {
      if (!/^[\w.]*?\w+$|^@?$/gi.test(this.newName)) return false
      if (
        this.newType == 'CNAME' &&
        (this.newName == '' || this.newName == '@')
      )
        return false
      return true
    },
    isValidValue() {
      if (!this.newValue) return false
      if (
        this.newType == 'A' &&
        !/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/gi.test(this.newValue)
      )
        return false
      if (
        this.newType == 'CNAME' &&
        !/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])\.?$/gi.test(
          this.newValue
        )
      )
        return false
      return true
    },
    isValidTTL() {
      return !!this.newTTL && this.newTTL > 60
    },
    canAddRecord() {
      return (
        this.isValidType &&
        this.isValidName &&
        this.isValidValue &&
        this.isValidTTL
      )
    },
  },
  watch: {
    records: {
      handler() {
        this.$emit('update', this.records)
      },
      deep: true,
    },
    readOnlyRecords: {
      handler() {
        this.records = this.readOnlyRecords
        this.$emit('update', this.records)
      },
      deep: true,
    },
  },
}
</script>

<style scoped lang="scss">
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
