<template>
  <div class="mt-8 mb-10 mx-auto w-4/5">
    <!-- Loading -->
    <Loading v-if="loading" />

    <!-- Info -->
    <div
      v-if="!loading && updated"
      class="p-4 rounded text-center"
      :class="{
        'text-green-700 bg-green-200 border-green-400': updated.success,
        'text-red-700 bg-red-200 border-red-400': !updated.success,
      }"
    >
      <span v-if="updated.success == true"
        >The zone has been hosted! Try `dig`ging it now.</span
      >
      <span v-else
        >There was a problem hosting this file: {{ updated.error }}</span
      >
    </div>

    <!-- Errors -->
    <div
      v-if="!loading && (!validation?.data?.valid || validation.error)"
      class="p-4 rounded text-center text-red-700 bg-red-200 border-red-400"
    >
      <p class="mb-2">
        There was a problem verifying the zone.
        <a
          @click="$router.push({ name: 'Home' })"
          class="underline cursor-pointer"
          >Try again.</a
        >
      </p>
      <pre v-if="validation?.data?.data?.check?.success !== true">{{
        validation?.data?.data?.check
      }}</pre>
      <pre v-if="validation?.data?.data?.verify?.success !== true">{{
        validation?.data?.data?.verify
      }}</pre>
      <pre v-if="validation.error">{{ validation.error }}</pre>
    </div>

    <!-- Table -->
    <ZoneTable
      v-if="!loading && validation?.data?.valid && !validation.error"
      :zone="validation.zone"
    />

    <!-- Status checks and actions -->
    <div v-if="!loading" class="mt-8 flex justify-between">
      <div>
        <input
          type="checkbox"
          v-model="checks.dsFoundOnChain"
          @click.prevent="false"
        />
        DS record exists on-chain and matches with this signed file<br />
        <input
          type="checkbox"
          v-model="checks.serialIncremented"
          @click.prevent="false"
        />
        Serial number has been incremented
        <span
          v-if="
            !checks.serialIncremented &&
              typeof this.validation.data?.data?.serialCheck === 'string'
          "
          >({{ validation.data.data.serialCheck.split(': ')[1] }})</span
        ><br />
        <input
          type="checkbox"
          v-model="checks.allRecordsSigned"
          @click.prevent="false"
        />
        All records are signed<br />
        <!-- <input
          type="checkbox"
          v-model="checks.accountIsFunded"
          @click.prevent="false"
        />
        Account is funded -->
      </div>
      <div class="flex-shrink-0">
        <button
          @click="updateZone"
          class="px-6 py-2 text-gray-100 bg-gray-700 rounded"
          :class="{
            'opacity-50 cursor-default': !canUpdate,
          }"
          :disabled="!canUpdate"
        >
          UPDATE
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ZoneTable from '@/components/ZoneTable.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: 'ZonePage',
  props: {
    zone: String,
  },
  components: {
    ZoneTable,
    Loading,
  },
  data() {
    return {
      loading: true,
      validation: null,
      updated: null,
      error: null,
      checks: {
        dsFoundOnChain: true,
        serialIncremented: true,
        allRecordsSigned: true,
        accountIsFunded: false,
      },
    }
  },
  mounted() {
    console.log(`${process.env.VUE_APP_API_BASE_URL}`)
    if (!this.zone.length) this.$router.replace({ name: 'Home' })
    else this.validateZone()
  },
  methods: {
    async validateZone() {
      if (this.zone.indexOf('$INCLUDE') !== -1) {
        this.loading = false
        this.checks.serialIncremented = false
        this.checks.allRecordsSigned = false
        this.checks.dsFoundOnChain = false
        this.validation = {
          error:
            'This zone is not signed. Check the Get Started page on the home page to learn how to sign the zone.',
        }
        return
      }
      const validateResponse = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}/validate`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ zone: this.zone }),
        }
      )
      this.validation = await validateResponse.json()
      if (!this.validation?.data?.valid || this.validation.error) {
        console.log(this.validation)
      }
      this.checks.dsFoundOnChain = this.validation?.data?.data?.check?.success
      this.checks.allRecordsSigned = this.validation?.data?.data?.verify?.success
      this.checks.serialIncremented = this.validation.data?.data?.serialCheck?.success
      this.loading = false
    },
    async updateZone() {
      this.loading = true
      const updateResponse = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}/update`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ zone: this.zone }),
        }
      )
      this.updated = await updateResponse.json()
      console.log(this.updated)
      this.loading = false
    },
  },
  computed: {
    canUpdate() {
      return (
        !this.loading &&
        this.checks.dsFoundOnChain &&
        this.checks.serialIncremented &&
        this.checks.allRecordsSigned &&
        this.validation?.data?.valid &&
        !this.validation.error &&
        (!this.updated || !this.updated.success)
      )
    },
  },
}
</script>
