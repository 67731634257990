<template>
  <div class="mt-8 mb-10 mx-auto w-4/5">
    <div class="">
      <span class="mr-3 text-gray-600 font-medium text-lg"
        >Create new zone for</span
      >
      <input
        v-model="domain"
        type="text"
        @keyup.enter="isDomainConfirmed = !!domain.length"
        class="px-2 py-1 rounded border"
        placeholder="domain"
        pattern="\w+"
        :disabled="isDomainConfirmed"
      />
      <button
        v-if="!isDomainConfirmed"
        @click="isDomainConfirmed = !!domain.length"
        class="ml-2 px-4 py-1 text-gray-100 bg-gray-700 rounded"
        :class="{
          'opacity-50 cursor-default': !domain.length,
        }"
        :disabled="!domain.length"
      >
        Set
      </button>
      <button v-else @click="changeDomain" class="px-4 py-1 text-gray-800">
        Change
      </button>
    </div>

    <!-- Table -->
    <EditableZoneTable
      v-if="isDomainConfirmed"
      class="mt-8"
      ref="zoneTable"
      @update="records = $event"
    />

    <!-- Easy Add -->
    <div v-if="isDomainConfirmed" class="mt-8 grid gap-4 md:grid-cols-2">
      <div
        @click="openModal('DaneModal')"
        class="p-4 text-green-50 bg-green-700 rounded-md cursor-pointer"
      >
        <h4 class="text-lg font-medium flex items-center">
          <svg
            class="mr-2 h-5 w-5 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.75 9H18V6c0-3.309-2.691-6-6-6S6 2.691 6 6v3h-.75A2.253 2.253 0 003 11.25v10.5C3 22.991 4.01 24 5.25 24h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5C21 10.009 19.99 9 18.75 9zM8 6c0-2.206 1.794-4 4-4s4 1.794 4 4v3H8zm5 10.722V19a1 1 0 11-2 0v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z"
            />
          </svg>
          HTTPS
        </h4>
        <p class="mt-2">Secure your handshake websites with HTTPS and DANE!</p>
      </div>
      <div
        @click="openModal('MailModal')"
        class="p-4 text-green-50 bg-blue-700 rounded-md cursor-pointer"
      >
        <h4 class="text-lg font-medium flex items-center">
          <svg
            class="mr-2 h-5 w-5 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M507.49 101.721L352.211 256 507.49 410.279c2.807-5.867 4.51-12.353 4.51-19.279V121c0-6.927-1.703-13.412-4.51-19.279zM467 76H45c-6.927 0-13.412 1.703-19.279 4.51l198.463 197.463c17.548 17.548 46.084 17.548 63.632 0L486.279 80.51C480.412 77.703 473.927 76 467 76zM4.51 101.721C1.703 107.588 0 114.073 0 121v270c0 6.927 1.703 13.413 4.51 19.279L159.789 256 4.51 101.721z"
            />
            <path
              d="M331 277.211l-21.973 21.973c-29.239 29.239-76.816 29.239-106.055 0L181 277.211 25.721 431.49C31.588 434.297 38.073 436 45 436h422c6.927 0 13.412-1.703 19.279-4.51L331 277.211z"
            />
          </svg>
          Email
        </h4>
        <p class="mt-2">
          Send and receive emails <span v-if="domain">@{{ domain }}</span>
          with hMail!
        </p>
      </div>
    </div>

    <!-- Zone Content -->
    <div v-if="isDomainConfirmed" class="mt-16">
      <div class="flex items-center justify-between">
        <h3 class="font-medium text-gray-600 text-2xl">Zone File</h3>
        <button
          v-if="domain"
          @click="downloadZoneFile"
          class="px-4 py-1 text-gray-100 bg-gray-700 rounded"
        >
          Download
        </button>
      </div>
      <pre
        v-if="domain"
        class="mt-6 p-4 rounded border shadow-sm bg-gray-50 overflow-x-auto"
        >{{ zoneContent }}</pre
      >
      <p v-else>Fill in the domain name on top to see the zone file.</p>
    </div>

    <!-- Modal -->
    <Modal :show="isModalOpen" @close="isModalOpen = false">
      <template v-slot:header>
        {{ modalHeading[selectedModal] }}
      </template>
      <template v-slot:body>
        <component
          :is="selectedModal"
          :domain="domain"
          @add-records="addRecords"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import EditableZoneTable from '@/components/EditableZoneTable.vue'
import DaneModal from '@/components/DaneModal.vue'
import MailModal from '@/components/MailModal.vue'
import Modal from '@/components/Modal.vue'

export default {
  name: 'CreateZone',
  components: {
    EditableZoneTable,
    DaneModal,
    MailModal,
    Modal,
  },
  data() {
    return {
      domain: '',
      isDomainConfirmed: false,
      records: [],
      isModalOpen: false,
      selectedModal: 'DaneModal',
      modalHeading: {
        DaneModal: 'HTTPS with DANE',
        MailModal: 'Mail with hMail',
      },
    }
  },
  mounted() {},
  methods: {
    changeDomain() {
      if (confirm('This will reset the form below. Are you sure?')) {
        this.isDomainConfirmed = false
        this.records = []
      }
    },
    downloadZoneFile() {
      download('text/dns', `${this.domain}.zone`, this.zoneContent)
    },
    openModal(component) {
      this.selectedModal = component
      this.isModalOpen = true
    },
    addRecords(records) {
      this.$refs.zoneTable.addRecords(records)
      this.isModalOpen = false
      records.forEach(record => this.records.push(record))
    },
  },
  computed: {
    zoneContent() {
      let recordLines = []
      // console.log(this.records)
      this.records.forEach(record => {
        recordLines.push(
          `${record.name}\t\t${record.ttl}\t${record.type}\t\t${record.value}`
        )
      })
      let zone = `
$ORIGIN ${this.domain}.
@       3600    SOA     ns1.rithvikvibhu.com. contact.${this.domain}. (
                              1         ; Serial
                         604800         ; Refresh
                          86400         ; Retry
                        2419200         ; Expire
                         604800 )       ; Negative Cache TTL

        86400   NS      ns1.rithvikvibhu.com.
${recordLines.join('\n')}

`
      return zone.trim()
    },
  },
}

function download(mime, filename, text) {
  var element = document.createElement('a')
  element.setAttribute(
    'href',
    `data:${mime};charset=utf-8,` + encodeURIComponent(text)
  )
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
</script>

<style lang="scss" scoped>
input[pattern]:invalid {
  border-color: rgba(239, 68, 68, 1);
}
</style>
