<template>
  <div class="text-gray-700">
    <p>
      Secure handshake websites with HTTPS using DANE so that https://{{
        domain
      }}
      doesn't throw warnings in browsers.
    </p>
    <p class="mt-2">
      <span class="font-medium">Note:</span> Until DANE is supported by
      browsers, a proxy like letsdane needs to be installed by users to trust
      the site.
    </p>

    <!-- Empty domain error -->
    <div v-if="!domain.length" class="mt-4">
      <p class="text-red-600 text-sm">
        Make sure the domain field is filled at the top of the page!
      </p>
    </div>

    <!-- Section 1 -->
    <section>
      <div class="mt-8 flex items-baseline font-medium">
        <div class="h-5 w-5 bg-gray-200 text-sm text-center rounded-full">
          1
        </div>
        <p class="ml-4">Generate TLS Certificate</p>
      </div>
      <div class="ml-9">
        <button
          @click="generateCertificate"
          class="mt-2 px-4 py-1 text-gray-100 bg-gray-700 rounded"
          :class="{
            'opacity-50 cursor-default': !domain.length,
          }"
          :disabled="!domain.length"
        >
          Generate Certificate
        </button>
        <button
          v-if="certificate.certificate"
          @click="downloadCertificateFiles"
          class="mt-2 px-4 py-1 text-gray-800"
        >
          download as files
        </button>
        <div
          v-if="certificate.certificate"
          class="mt-4 grid gap-4 grid-cols-2 text-sm font-mono"
        >
          <pre class="h-40 overflow-auto rounded border select-all">{{
            certificate.certificate
          }}</pre>
          <pre class="h-40 overflow-auto rounded border select-all">{{
            certificate.privateKey
          }}</pre>
        </div>
      </div>
    </section>

    <!-- Section 2 -->
    <section>
      <div class="mt-8 flex items-baseline font-medium">
        <div class="h-5 w-5 bg-gray-200 text-sm text-center rounded-full">
          2
        </div>
        <p class="ml-4">Generate TLSA record</p>
      </div>
      <div class="ml-9">
        <p>
          This record is based on the certificate generated above:
        </p>
        <pre
          v-if="certificate.certificate"
          class="mt-2 p-2 font-mono overflow-x-auto rounded border select-all"
          >{{ tlsaHash ? `Hash: ${tlsaHash}` : 'Generating...' }}</pre
        >
      </div>
    </section>

    <!-- Section 3 -->
    <section>
      <div class="mt-8 flex items-baseline font-medium">
        <div class="h-5 w-5 bg-gray-200 text-sm text-center rounded-full">
          3
        </div>
        <p class="ml-4">Confirm</p>
      </div>
      <div class="ml-9 mt-2">
        <p>
          This record will be added to the zone:
        </p>
        <EditableZoneTable
          class="mt-4 overflow-hidden"
          :editable="false"
          :readOnlyRecords="records"
        />
      </div>
      <div class="ml-9 mt-2"></div>
    </section>

    <!-- Section 2 -->
    <section>
      <div class="mt-8 flex items-baseline font-medium">
        <div class="h-5 w-5 bg-gray-200 text-sm text-center rounded-full">
          4
        </div>
        <p class="ml-4">Update your website</p>
      </div>
      <div class="ml-9">
        <p>
          Download the certificate and private key above and set it with your
          website host. This can be done later too, and won't affect the
          website's uptime.<br />
          For example, Cloudways (by Digital Ocean) has
          <a
            href="https://support.cloudways.com/how-to-install-custom-ssl-certificate/"
            target="_blank"
            rel="noopener noreferrer"
            class="underline"
            >docs here.</a
          >
        </p>
      </div>
    </section>

    <!-- Add button -->
    <div class="mt-6 mb-2 flex items-center justify-center">
      <button
        v-if="domain.length"
        @click="$emit('add-records', [])"
        class="px-4 py-1 text-gray-800 rounded"
      >
        cancel
      </button>
      <button
        v-if="domain.length"
        @click="addRecords"
        class="px-6 py-1 text-gray-100 bg-gray-700 rounded"
        :class="{
          'opacity-50 cursor-default': !canAdd,
        }"
        :disabled="!canAdd"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script>
// import * as x509 from '@peculiar/x509'
import { generateCertificate, generateTLSAHash } from '@/util'
import EditableZoneTable from '@/components/EditableZoneTable.vue'

export default {
  name: 'DaneModal',
  props: {
    domain: {
      type: String,
      default: 'domain',
    },
  },
  components: {
    EditableZoneTable,
  },
  data() {
    return {
      wantSubdomain: false,
      user: '',
      subdomain: '',
      certificate: {},
      tlsaHash: '',
    }
  },
  methods: {
    addRecords() {
      this.$emit('add-records', this.records)
    },
    async generateCertificate() {
      this.tlsaHash = null
      const result = await generateCertificate(this.domain)
      console.log(result)
      this.certificate = result
      this.generateTLSAHash()
    },
    async generateTLSAHash() {
      // const hexHash = await generateTLSAHash(this.certificate.certificate)
      const hexHash = await generateTLSAHash(this.certificate.publicKey)
      console.log(hexHash)
      this.tlsaHash = hexHash
    },
    downloadCertificateFiles() {
      // private: application/pkcs8+pem
      // cert: application/x-x509-ca-cert
      download(
        'application/x-x509-ca-cert',
        `${this.domain}.pem`,
        this.certificate.certificate
      )
      download(
        'application/pkcs8+pem',
        `${this.domain}.key`,
        this.certificate.privateKey
      )
    },
  },
  computed: {
    records() {
      return [
        {
          type: 'TLSA',
          name: '_443._tcp',
          value: `3 1 1 ${this.tlsaHash}`,
          ttl: 3600,
        },
      ]
    },
    canAdd() {
      return this.domain.length && this.tlsaHash
    },
  },
}

function download(mime, filename, text) {
  var element = document.createElement('a')
  element.setAttribute(
    'href',
    `data:${mime};charset=utf-8,` + encodeURIComponent(text)
  )
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
</script>

<style scoped lang="scss"></style>
